import { VStack } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FiRotateCcw, FiRotateCw } from "react-icons/fi"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { useImage } from "~/hooks/useImage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useProduct } from "~/hooks/useProduct"
import { useUpload } from "~/hooks/useUpload"
import { getDefaultImageTransformation } from "~/services/Utils"
import {
  imageTransformationMinZoomStateFamily,
  imageTransformationRotationStateFamily,
  imageTransformationTranslationStateFamily,
  imageTransformationZoomStateFamily,
  editorCurrentProductId,
} from "./atoms"
import EditorButton from "./EditorButton"

interface Props {
  imageRegionId: EntityId
  imageTransformId: EntityId
}

const EditorRotateTool: React.FC<Props> = ({
  imageRegionId,
  imageTransformId,
}) => {
  const { t } = useTranslation()
  const { imageRegion } = useImageRegion(imageRegionId)
  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId!)
  const productId = useRecoilValue(editorCurrentProductId)
  const { product } = useProduct(productId)

  const isClampingEnabled = Boolean(
    product?.metaData?.web?.editor?.clamp ?? true
  )
  //only photo-book products allow portrait
  const allowPortrait = product?.categoryName === "photo-book"

  const [rotation, setRotation] = useRecoilState(
    imageTransformationRotationStateFamily(imageTransformId)
  )

  const setMinZoom = useSetRecoilState(
    imageTransformationMinZoomStateFamily(imageTransformId)
  )

  const setZoom = useSetRecoilState(
    imageTransformationZoomStateFamily(imageTransformId)
  )

  const setTranslation = useSetRecoilState(
    imageTransformationTranslationStateFamily(imageTransformId)
  )

  const regionSize = useMemo(() => {
    return {
      width: imageRegion?.window.width || 0,
      height: imageRegion?.window.height || 0,
    }
  }, [imageRegion])

  const imageSize = useMemo(() => {
    return {
      width: image?.width || 0,
      height: image?.height || 0,
    }
  }, [image])

  const applyRotationAndTransforms = useCallback(
    (newRotation: number) => {
      if (isClampingEnabled) {
        const { x, y, zoom } = getDefaultImageTransformation(
          regionSize,
          imageSize,
          newRotation,
          allowPortrait
        )
        setTranslation({ x, y })
        setZoom(zoom)
        setMinZoom(zoom)
      }
      if (allowPortrait) {
        const { minZoom } = getDefaultImageTransformation(
          regionSize,
          imageSize,
          newRotation,
          allowPortrait
        )
        setMinZoom(minZoom)
      }

      setRotation(newRotation)
    },
    [
      allowPortrait,
      imageSize,
      isClampingEnabled,
      regionSize,
      setMinZoom,
      setRotation,
      setTranslation,
      setZoom,
    ]
  )

  const onRotateClockwise = useCallback(() => {
    applyRotationAndTransforms(rotation + 90)
  }, [applyRotationAndTransforms, rotation])

  const onRotateCounterClockwise = useCallback(() => {
    applyRotationAndTransforms(rotation - 90)
  }, [applyRotationAndTransforms, rotation])

  return (
    <VStack spacing={2} width="full">
      <EditorButton
        id="rotate-clockwise"
        leftIcon={<FiRotateCw />}
        onClick={onRotateClockwise}
      >
        {t("components.editors.common.EditorRotateTool.Clockwise")}
      </EditorButton>
      <EditorButton
        id="rotate-counter-clockwise"
        leftIcon={<FiRotateCcw />}
        onClick={onRotateCounterClockwise}
      >
        {t("components.editors.common.EditorRotateTool.CounterClockwise")}
      </EditorButton>
    </VStack>
  )
}

export default EditorRotateTool
