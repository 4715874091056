import { Flex, FlexProps } from "@chakra-ui/react"
import React from "react"

interface Props extends FlexProps {
  pageHeight: number
  pageWidth: number
  baseHeight?: number
  isMobile?: boolean
}

const BlankPage: React.FC<Props> = ({
  pageHeight,
  pageWidth,
  baseHeight = 80,
  isMobile = false,
  ...props
}) => {
  const ratio = pageWidth / pageHeight
  const boxWidth = baseHeight * ratio

  return (
    <Flex
      w={isMobile ? "full" : `${boxWidth}px`}
      {...props}
      aria-label="blankpage"
    >
      <svg
        x={0}
        y={0}
        viewBox={`0 0 ${pageWidth} ${pageHeight}`}
        style={{ width: "100%", height: "100%" }}
      >
        <rect
          x={0}
          y={0}
          width={`${pageWidth}px`}
          height={`${pageHeight}px`}
          fill="#D9D9D9"
        />
      </svg>
    </Flex>
  )
}

export default BlankPage
