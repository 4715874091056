import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useLineItem } from "~/hooks/useLineItem"
import DesktopTopbar from "../common/DesktopTopbar"
import EditorSvgCropperMobileToolBar from "../common/EditorSvgCropperMobileToolBar"
import EditorSvgCropperMobileToolBarLandscape from "../common/EditorSvgCropperMobileToolBarLandscape"
import EditorContainer from "../common/layouts/EditorContainer"
import EditorContainerMobile from "../common/layouts/EditorContainerMobile"
import EditorContainerMobileLandscape from "../common/layouts/EditorContainerMobileLandscape"
import MultiplePagesEditor from "../common/MultiplePagesEditor"
import PageEditorMobilePageNavigation from "../common/pagination/PageEditorMobilePageNavigation"
import PageEditorPageNavigation from "../common/pagination/PageEditorPageNavigation"
import { ViewType } from "../Editor"
import PrintEditorBottombarMobile from "./PrintEditorBottombarMobile"
import PrintEditorSideBar from "./PrintEditorSidebar"
import PrintEditorTopbarMobile from "./PrintEditorTopbarMobile"

interface Props {
  lineItemId: EntityId
  onComplete: () => void
  viewType: ViewType
}

export enum DesktopToolBarExperiment {
  showInSidebar,
  showInTopbar,
}

const PrintEditor: React.FC<Props> = ({ lineItemId, onComplete, viewType }) => {
  const { lineItem } = useLineItem(lineItemId)
  const hasMultiplePages = lineItem.productPageIds.length > 1

  const onContinue = useCallback(() => {
    onComplete()
  }, [onComplete])

  const onCancel = useCallback(() => {
    onComplete()
  }, [onComplete])

  const desktopToolBarExperiment = DesktopToolBarExperiment.showInSidebar
  // useGoogleOptimize(settings.googleOptimizeExperimentIdSix, [
  //   DesktopToolBarExperiment.showInSidebar,
  //   DesktopToolBarExperiment.showInTopbar,
  // ]) ?? DesktopToolBarExperiment.showInSidebar

  if (viewType === "mobile") {
    return (
      <EditorContainerMobile
        topbar={<PrintEditorTopbarMobile lineItemId={lineItemId} />}
        editor={
          <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
        }
        toolbar={<EditorSvgCropperMobileToolBar lineItemId={lineItemId} />}
        bottombar={
          <PrintEditorBottombarMobile
            lineItemId={lineItemId}
            onContinue={onContinue}
            onCancel={onCancel}
          />
        }
        pageNavigator={
          hasMultiplePages ? (
            <PageEditorMobilePageNavigation lineItemId={lineItemId} />
          ) : undefined
        }
      />
    )
  }

  if (viewType === "mobile landscape") {
    return (
      <EditorContainerMobileLandscape
        topbar={<PrintEditorTopbarMobile lineItemId={lineItemId} />}
        editor={
          <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
        }
        toolbar={
          <EditorSvgCropperMobileToolBarLandscape lineItemId={lineItemId} />
        }
        bottombar={
          <PrintEditorBottombarMobile
            lineItemId={lineItemId}
            onContinue={onContinue}
            onCancel={onCancel}
          />
        }
        pageNavigator={
          hasMultiplePages ? (
            <PageEditorMobilePageNavigation lineItemId={lineItemId} />
          ) : undefined
        }
      />
    )
  }

  //Desktop view
  return (
    <EditorContainer
      topbar={<DesktopTopbar lineItemId={lineItemId} />}
      sidebar={
        <PrintEditorSideBar
          lineItemId={lineItemId}
          onContinue={onContinue}
          onCancel={onCancel}
          showTools={
            desktopToolBarExperiment === DesktopToolBarExperiment.showInSidebar
          }
        />
      }
      editor={
        <MultiplePagesEditor lineItemId={lineItemId} viewType={viewType} />
      }
      pageNavigator={
        hasMultiplePages ? (
          <PageEditorPageNavigation lineItemId={lineItemId} />
        ) : undefined
      }
    />
  )
}

export default PrintEditor
