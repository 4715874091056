import { Flex, FlexProps, Text } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { PhotoBookPage } from "./hooks/usePhotoBookPages"

interface Props extends FlexProps {
  productPageId?: EntityId
  productPageIds: EntityId[]
  type: PhotoBookPage
}

const PhotoBookPageName: React.FC<Props> = ({
  type,
  productPageId = "",
  productPageIds,
  ...props
}) => {
  const { t } = useTranslation()

  const pageIndex = productPageIds.indexOf(productPageId)
  const isCover = type === "cover"
  const isStart = type === "start"
  const isEnd = type === "end"
  const isPage = type === "page"

  const blankPageComponent = (
    <Text fontSize="sm" textAlign="center" lineHeight="shorter">
      {t("components.editors.common.pagination.PhotoBookPageName.BlankPage")}
    </Text>
  )

  return (
    <Flex {...props}>
      {isCover && (
        <Text fontSize="sm" textAlign="center" lineHeight="shorter">
          {t(
            "components.editors.common.pagination.PhotoBookPageName.FrontCover"
          )}
        </Text>
      )}

      {isStart && blankPageComponent}

      {isPage && (
        <Text
          key={productPageId}
          fontSize="sm"
          textAlign="center"
          lineHeight="shorter"
        >
          {t("components.editors.common.pagination.PhotoBookPageName.Page")}{" "}
          {pageIndex + 1}
        </Text>
      )}

      {isEnd && blankPageComponent}
    </Flex>
  )
}

export default PhotoBookPageName
