import { Box, Heading } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { useImage } from "~/hooks/useImage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useUpload } from "~/hooks/useUpload"
import { selectedRegion } from "../common/atoms"
import EditorChangeImageButton from "../common/EditorChangeImageButton"
import EditorRotateTool from "../common/EditorRotateTool"
import EditorZoomSlider from "../common/EditorZoomSlider"

interface Props {
  lineItemId: EntityId
}

const TemplateEditorSidebarControlsImage: React.FC<Props> = ({ lineItemId }) => {
  const { t } = useTranslation()
  const { id: selectedRegionId } = useRecoilValue(selectedRegion)

  const { imageRegion } = useImageRegion(selectedRegionId)

  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId!)

  const imageTransformId = image?.imageTransformId!

  if (!image) {
    return null
  }

  return <>
    <Box>
      <Heading as="h3" size="sm" mb={3}>
        {t(
          "components.editors.template.TemplateEditorSidebar.Customize"
        )}
      </Heading>
      <EditorChangeImageButton
        imageRegionId={imageRegion?.id!}
        lineItemId={lineItemId}
      />
    </Box>
    <Box>
      <Heading as="h3" size="sm" mb={3}>
        {t(
          "components.editors.template.TemplateEditorSidebar.RotateImage"
        )}
      </Heading>
      <EditorRotateTool
        imageRegionId={imageRegion?.id!}
        imageTransformId={imageTransformId}
      />
    </Box>
    <Box>
      <Heading as="h3" size="sm" mb={3}>
        {t(
          "components.editors.template.TemplateEditorSidebar.ScaleImage"
        )}
      </Heading>
      <EditorZoomSlider
        imageTransformId={imageTransformId}
        disabled={false}
      />
    </Box>
  </>
}

export default TemplateEditorSidebarControlsImage