import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useEffectOnce } from "react-use"
import { useRecoilState } from "recoil"
import { useLineItem } from "~/hooks/useLineItem"
import { photoBookPageIdsFamily } from "../atoms"
import { usePhotoBookDnd } from "../hooks/usePhotoBookDnd"
import { usePhotoBookNavigatorDisclosure } from "./hooks/usePhotoBookNavigatorDisclosure"
import PhotoBookPageList from "./PhotoBookPageList"

interface Props {
  lineItemId: EntityId
  width?: number
}

const PhotoBookAllPagesNavigation: React.FC<Props> = ({
  lineItemId,
  width,
}) => {
  const { isOpen } = usePhotoBookNavigatorDisclosure()
  const {
    lineItem: { productPageIds },
  } = useLineItem(lineItemId)
  const { handleDragEnd, handleOnClose } = usePhotoBookDnd(lineItemId)
  const [photoBookPageIds, setPhotoBookPageIds] = useRecoilState(
    photoBookPageIdsFamily(lineItemId)
  )

  useEffectOnce(() => {
    setPhotoBookPageIds(productPageIds)
  })

  const maxWidth = width ? `${width + 20}px` : "full"

  return (
    <Modal
      size="full"
      motionPreset="slideInBottom"
      isOpen={isOpen}
      onClose={handleOnClose}
    >
      <ModalContent maxW={maxWidth} left="125px" my={3}>
        <ModalCloseButton />
        <ModalHeader textAlign="center">All Pages</ModalHeader>

        <ModalBody>
          <PhotoBookPageList
            pageIds={photoBookPageIds}
            handleDragEnd={handleDragEnd}
            lineItemId={lineItemId}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PhotoBookAllPagesNavigation
