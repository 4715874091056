import { useInterval } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import { clamp } from "lodash"
import { useCallback, useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import {
  imageTransformationRotationStateFamily,
  imageTransformationTranslationStateFamily,
  imageTransformationZoomStateFamily,
} from "~/components/editors/common/atoms"
import { getDefaultImageTransformation } from "~/services/Utils"
import { useImage } from "~/hooks/useImage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useImageTransform } from "~/hooks/useImageTransform"
import { useLineItem } from "~/hooks/useLineItem"
import { useProcessActions } from "~/hooks/useProcessActions"
import { useUpload } from "~/hooks/useUpload"

export const useSvgCropperToolBarHandlers = (
  lineItemId: EntityId,
  imageRegionId: EntityId
) => {
  const { lineItem } = useLineItem(lineItemId)
  const { imageRegion } = useImageRegion(imageRegionId)
  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId!)
  const { imageTransform, zoomStep, minZoom, maxZoom } = useImageTransform(
    image?.imageTransformId!
  )
  const imageTransformId = imageTransform?.id

  const process = useProcessActions()

  const [rotation, setRotation] = useRecoilState(
    imageTransformationRotationStateFamily(imageTransformId!)
  )

  const setZoom = useSetRecoilState(
    imageTransformationZoomStateFamily(imageTransformId!)
  )

  const setTranslation = useSetRecoilState(
    imageTransformationTranslationStateFamily(imageTransformId!)
  )

  const regionSize = {
    width: imageRegion?.window.width || 0,
    height: imageRegion?.window.height || 0,
  }

  const imageSize = {
    width: image?.width || 0,
    height: image?.height || 0,
  }

  const applyRotationAndTransforms = useCallback(
    newRotation => {
      const { x, y, zoom } = getDefaultImageTransformation(
        {
          width: regionSize.width,
          height: regionSize.height,
        },
        {
          width: imageSize.width,
          height: imageSize.height,
        },
        newRotation
      )
      setRotation(newRotation)
      setTranslation({ x, y })
      setZoom(zoom)
    },
    [
      imageSize.width,
      imageSize.height,
      regionSize.width,
      regionSize.height,
      setRotation,
      setTranslation,
      setZoom,
    ]
  )

  const handleRotateCcw = useCallback(() => {
    applyRotationAndTransforms(rotation - 90)
  }, [applyRotationAndTransforms, rotation])

  const handleToggleOrientation = useCallback(() => {
    const orientation =
      lineItem.orientation === "landscape" ? "portrait" : "landscape"
    process.updateLineItemOrientation({
      lineItemId,
      orientation,
    })
  }, [lineItem.orientation, lineItemId, process])

  const handleZoomIn = useCallback(() => {
    setZoom(z => clamp(z + zoomStep, minZoom, maxZoom))
  }, [maxZoom, minZoom, setZoom, zoomStep])

  const handleZoomOut = useCallback(() => {
    setZoom(z => clamp(z - zoomStep, minZoom, maxZoom))
  }, [maxZoom, minZoom, setZoom, zoomStep])

  const [isZoomingIn, setIsZoomingIn] = useState(false)
  const [isZoomingOut, setIsZoomingOut] = useState(false)

  const handleStartZoomingIn = useCallback(() => {
    setIsZoomingIn(true)
  }, [])

  const handleStopZoomingIn = useCallback(() => {
    setIsZoomingIn(false)
  }, [])

  const handleStartZoomingOut = useCallback(() => {
    setIsZoomingOut(true)
  }, [])

  const handleStopZoomingOut = useCallback(() => {
    setIsZoomingOut(false)
  }, [])

  useInterval(
    () => {
      handleZoomIn()
    },
    isZoomingIn ? 50 : null
  )

  useInterval(
    () => {
      handleZoomOut()
    },
    isZoomingOut ? 50 : null
  )

  return {
    handleRotateCcw,
    handleToggleOrientation,
    handleZoomIn,
    handleZoomOut,
    handleStartZoomingIn,
    handleStartZoomingOut,
    handleStopZoomingIn,
    handleStopZoomingOut,
  }
}
