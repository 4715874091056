import { Box, Flex, Icon, SimpleGrid, Text } from "@chakra-ui/react"
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  useDroppable,
} from "@dnd-kit/core"
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { MdAdsClick } from "react-icons/md"
import { useLineItem } from "~/hooks/useLineItem"
import { usePhotoBook } from "~/hooks/usePhotoBook"
import { getOrientationDimensions } from "~/services/Utils"
import PhotoBookPage from "../layers/PhotoBookPage"
import BlankPage from "../photo-book/BlankPage"
import Cover from "../photo-book/Cover"

interface Props {
  lineItemId: EntityId
  pageIds: EntityId[]
  handleDragEnd: (event: DragEndEvent) => void
}

const PhotoBookPageList: React.FC<Props> = ({
  lineItemId,
  pageIds,
  handleDragEnd,
}) => {
  const { t } = useTranslation()
  const {
    product,
    lineItem: { orientation },
  } = useLineItem(lineItemId)

  const {
    hasCover,
    photoBookCoverThumbnail: { thumbnail, metaData },
  } = usePhotoBook(lineItemId)

  const { setNodeRef } = useDroppable({
    id: `view-all-${lineItemId}`,
  })

  const { width: coverWidth, height: coverHeight } = metaData.photoBook!

  const { width, height } = getOrientationDimensions(
    product.pixelWidth,
    product.pixelHeight,
    orientation ?? product.metaData?.orientationOverride ?? "landscape"
  )

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext strategy={rectSortingStrategy} items={[...pageIds]}>
        <SimpleGrid
          ref={setNodeRef}
          columns={{
            base: 4,
            xl: 6,
          }}
          rowGap={12}
        >
          <Flex justify="flex-end">
            <Flex
              maxW={28}
              pr={3}
              gap={1}
              direction="column"
              align="center"
              justify="center"
            >
              <Icon as={MdAdsClick} />
              <Text fontSize="xs" textAlign="center">
                {t(
                  "components.editors.common.pagination.PhotoBookPageList.DragNDrop"
                )}
              </Text>
            </Flex>
          </Flex>
          {hasCover && (
            <Flex
              p={1}
              mr={4}
              boxShadow="0px 2.1px 12.57px 0px rgba(0, 0, 0, 0.20);"
              borderRadius="md"
              align="center"
            >
              <Cover
                baseHeight={80}
                height={coverHeight}
                width={coverWidth}
                image={thumbnail}
              />
            </Flex>
          )}

          <Flex justify="flex-end">
            <Box
              p={1}
              borderLeftRadius="md"
              boxShadow="0px 2.1px 12.57px 0px rgba(0, 0, 0, 0.20);"
            >
              <BlankPage
                baseHeight={80}
                pageHeight={height}
                pageWidth={width}
              />
            </Box>
          </Flex>

          {pageIds.map((id, index) => {
            return (
              <PhotoBookPage
                pageIndex={index}
                key={id}
                lineItemId={lineItemId}
                productPageId={id}
              />
            )
          })}

          <Flex>
            <Box
              p={1}
              borderRightRadius="md"
              boxShadow="0px 2.1px 12.57px 0px rgba(0, 0, 0, 0.20);"
            >
              <BlankPage
                baseHeight={80}
                pageHeight={height}
                pageWidth={width}
              />
            </Box>
          </Flex>
        </SimpleGrid>
      </SortableContext>
    </DndContext>
  )
}

export default PhotoBookPageList
