import { useDisclosure } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import { useCallback } from "react"
import { useSetRecoilState } from "recoil"
import { useImageRegionActions } from "~/hooks/useImageRegion"
import { useLineItemActions } from "~/hooks/useLineItem"
import { useProcessActions } from "~/hooks/useProcessActions"
import { selectedRegion } from "../atoms"

export const useUpdatePhoto = ({
  lineItemId,
  imageRegionId,
}: {
  lineItemId: EntityId
  imageRegionId: EntityId
}) => {
  const {
    isOpen: isConfirmDialogOpen,
    onClose: closeConfirmDialog,
    onOpen: openConfirmDialog,
  } = useDisclosure()

  const { setUpdateMissingImagesDialogChoice } = useProcessActions()
  const { updateImage } = useImageRegionActions()
  const { updateMissingImages } = useLineItemActions()
  const setSelectedRegion = useSetRecoilState(selectedRegion)

  const onPhotoChange = useCallback(
    (files: File[]) => {
      if (files.length === 1) {
        updateImage(imageRegionId, files[0])
        setSelectedRegion({ type: "image", id: imageRegionId })
      } else {
        updateMissingImages(
          lineItemId,
          files,
          openConfirmDialog,
          closeConfirmDialog
        )
      }
    },
    [
      closeConfirmDialog,
      imageRegionId,
      lineItemId,
      openConfirmDialog,
      updateImage,
      updateMissingImages,
      setSelectedRegion,
    ]
  )

  const onConfirm = useCallback(() => {
    setUpdateMissingImagesDialogChoice({ choice: true })
  }, [setUpdateMissingImagesDialogChoice])

  const onCancel = useCallback(() => {
    setUpdateMissingImagesDialogChoice({ choice: false })
  }, [setUpdateMissingImagesDialogChoice])

  return {
    isConfirmDialogOpen,
    onPhotoChange,
    onConfirm,
    onCancel,
  }
}
