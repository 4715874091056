import { EntityId } from "@jackfruit/common"
import { flatten } from "lodash"
import { useImageRegions } from "./useImageRegions"
import { useLineItem } from "./useLineItem"
import { useProductPages } from "./useProductPages"
import { useUploadEntities } from "./useUploadEntities"

export const useAllPageUploadsAreReadyForLineItem = (lineItemId: EntityId) => {
  const { lineItem } = useLineItem(lineItemId)
  const { productPageIds } = lineItem
  const { productPages } = useProductPages(productPageIds)
  const allImageRegionIds = flatten(
    productPages.map(productPage => productPage.imageRegionIds)
  )
  const { imageRegions } = useImageRegions(allImageRegionIds)
  const allUploadIds = imageRegions
    .filter(imageRegion => imageRegion.uploadId)
    .map(imageRegion => imageRegion.uploadId as EntityId)

  const { uploads } = useUploadEntities(allUploadIds)

  return uploads.every(upload => upload.status === "ready")
}
