import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { useSetRecoilState } from "recoil"
import ConfirmationModal from "~/components/ConfirmationModal"
import { UPLOAD_ACCEPTED_FILE_TYPES } from "~/data/const"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useUpdatePhoto } from "../hooks/useUpdatePhoto"
import { regionActivation } from "../atoms"

interface Props {
  lineItemId: EntityId
  imageRegionId: EntityId
}

const EditorSvgUploadZoneInput: React.FC<Props> = ({
  lineItemId,
  imageRegionId,
}) => {
  const { isConfirmDialogOpen, onPhotoChange, onConfirm, onCancel } =
    useUpdatePhoto({ lineItemId, imageRegionId })
  const { imageRegion } = useImageRegion(imageRegionId)
  const setImageRegionIsActive = useSetRecoilState(
    regionActivation({ type: "image", id: imageRegionId })
  )

  const handleMouseEnter = useCallback(() => {
    setImageRegionIsActive(true)
  }, [setImageRegionIsActive])
  const handleMouseLeave = useCallback(() => {
    setImageRegionIsActive(false)
  }, [setImageRegionIsActive])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onPhotoChange,
    accept: UPLOAD_ACCEPTED_FILE_TYPES,
  })
  const { width, height } = imageRegion?.window!

  return (
    <>
      <rect
        x={0}
        y={0}
        width={width}
        height={height}
        fill="transparent"
        cursor="pointer"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        {...getRootProps()}
      >
        <foreignObject>
          <input {...getInputProps()} />
        </foreignObject>
      </rect>
      <ConfirmationModal
        title="Please review your choice"
        description="Choosing multiple images will try to only fill the empty pages on this product. Your existing images will not be replaced."
        confirmLabel="Proceed"
        isOpen={isConfirmDialogOpen}
        onConfirm={onConfirm}
        onClose={onCancel}
        isCentered={true}
      />
    </>
  )
}

export default EditorSvgUploadZoneInput
