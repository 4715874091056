import { Button, Flex, Icon, Text } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi"
import { usePageEditorPagination } from "./hooks/usePageEditorPagination"

interface Props {
  lineItemId: EntityId
}

const PageEditorPager: React.FC<Props> = ({ lineItemId }) => {
  const { t } = useTranslation()

  const { hasPrevious, hasNext, handlePrevious, handleNext, currentPageLabel } =
    usePageEditorPagination(lineItemId, true)

  return (
    <Flex w="full" justifyContent="center">
      <Flex width="80%" minHeight={16} maxWidth="2xl" alignItems="center">
        <Flex flex={1}>
          <Button
            id="previous-page-button"
            isDisabled={!hasPrevious}
            variant="link"
            leftIcon={<Icon as={FiChevronLeft} boxSize={6} />}
            onClick={handlePrevious}
          >
            {t(
              "components.editors.common.pagination.PageEditorPager.PreviousButton"
            )}
          </Button>
        </Flex>
        <Flex flex={1} justifyContent="center">
          <Text textAlign="center">{currentPageLabel}</Text>
        </Flex>
        <Flex flex={1} justifyContent="flex-end">
          <Button
            id="next-page-button"
            isDisabled={!hasNext}
            variant="link"
            rightIcon={<Icon as={FiChevronRight} boxSize={6} />}
            onClick={handleNext}
          >
            {t(
              "components.editors.common.pagination.PageEditorPager.NextButton"
            )}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PageEditorPager
