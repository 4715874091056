import {
  Box,
  Center,
  CircularProgress,
  Flex,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { EntityId } from "@jackfruit/common"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { FiTrash2 } from "react-icons/fi"
import { useSetRecoilState } from "recoil"
import ConfirmationModal from "~/components/ConfirmationModal"
import { useImage } from "~/hooks/useImage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { useProductPage } from "~/hooks/useProductPage"
import { useTextRegions } from "~/hooks/useTextRegions"
import { useUpload } from "~/hooks/useUpload"
import { sanitizeTemplate } from "~/services/SvgUtils"
import ImageRegionPreview from "../ImageRegionPreview"
import { currentSelectedPageIdFamily } from "../pagination/atoms"
import { boxHeight } from "../pagination/PageNavigationButton"
import PhotoBookPageWrapper from "../pagination/PhotoBookPageWrapper"
import { TextRegionPreview } from "../TextRegionPreview"

interface Props {
  pageIndex: number
  productPageId: EntityId
  lineItemId: EntityId
  isActive?: boolean
  isMobile?: boolean
}

const PhotoBookPage: React.FC<Props> = ({
  productPageId,
  pageIndex,
  isActive,
  lineItemId,
  isMobile = false,
}) => {
  const { t } = useTranslation()
  const { lineItem, product } = useLineItem(lineItemId)
  const { productPageIds } = lineItem
  const { minImages } = product
  const { productPage } = useProductPage(productPageId)
  const imageRegionIds = productPage!.imageRegionIds ?? []
  const textRegionIds = productPage!.textRegionIds ?? []
  const { imageRegion } = useImageRegion(imageRegionIds[0])
  const { textRegions } = useTextRegions(textRegionIds)
  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId ?? 0)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { removePageSpread } = useLineItemActions()

  const setCurrentSelectedPageId = useSetRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )

  const canShowImage = image && image.localUrl
  const isImageLoading = image && !canShowImage
  const isImageUploaded = Boolean(imageRegion?.uploadId)
  const disableDnd = !isImageUploaded || isImageLoading

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    newIndex,
    isDragging,
  } = useSortable({
    id: productPageId,
    disabled: disableDnd,
  })

  const isOdd = newIndex % 2 === 0
  const isEven = newIndex % 2 === 1

  const isRightPage = isOdd
  const isFirstPage = pageIndex === 0
  const canRemovePages = productPageIds.length > minImages
  const shouldShowDeleteButton = isRightPage && !isFirstPage && canRemovePages

  const sanitizedSvg = useMemo(() => {
    return sanitizeTemplate(productPage!.svg || "")
  }, [productPage])

  const ratio = productPage!.width / productPage!.height
  const boxWidth = boxHeight * ratio

  const handleDeleteSpread = () => {
    onOpen()
  }

  const selectPreviousPageSpread = () => {
    const previousPageSpreadIndex = productPageIds.indexOf(productPageId) - 2
    const previousPageSpreadFirstPageId =
      productPageIds[previousPageSpreadIndex]

    if (Boolean(previousPageSpreadFirstPageId)) {
      setCurrentSelectedPageId(previousPageSpreadFirstPageId)
    }
  }

  const handleDeleteSpreadConfirmation = () => {
    selectPreviousPageSpread()
    removePageSpread(lineItemId, productPageId)
    onClose()
  }

  const page = (
    <svg viewBox={`0 0 ${productPage?.width} ${productPage?.height}`}>
      {!isImageUploaded && (
        <rect
          x={0}
          y={0}
          width={productPage?.width}
          height={productPage?.height}
          fill="#D9D9D9"
        />
      )}
      {imageRegionIds.map(imageRegionId => (
        <ImageRegionPreview
          key={imageRegionId}
          imageRegionId={imageRegionId}
          isRealTime={true}
        />
      ))}
      <g
        width="100%"
        height="100%"
        style={{ pointerEvents: "none" }}
        dangerouslySetInnerHTML={{
          __html: sanitizedSvg,
        }}
      ></g>
      {textRegions.map(textRegion => (
        <TextRegionPreview key={textRegion.id} textRegionId={textRegion.id} />
      ))}
    </svg>
  )

  const mobileComponent = (
    <PhotoBookPageWrapper
      pageName={`${t("components.editors.common.layers.PhotoBookPage.Page")} ${
        pageIndex + 1
      }`}
    >
      {shouldShowDeleteButton && (
        <Box position="absolute" top={2} right={2} zIndex={10}>
          <IconButton
            id={`photobook-remove-page-spread-${productPageId}`}
            boxShadow="md"
            size="sm"
            icon={<FiTrash2 />}
            colorScheme="gray"
            rounded="full"
            aria-label="remove page spread from photobook"
            onClick={handleDeleteSpread}
          />
          <DeleteSpreadConfirmation
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={handleDeleteSpreadConfirmation}
          />
        </Box>
      )}
      <Box
        w="full"
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        transform={CSS.Translate.toString(transform)}
        transition={transition || undefined}
        cursor="grab"
        p={1}
        boxShadow="0px 2.1px 12.57px 0px rgba(0, 0, 0, 0.20);"
        borderWidth={2}
        borderColor={isActive && !isDragging ? "primary.500" : "transparent"}
        borderLeftRadius={isOdd ? 0 : "md"}
        borderRightRadius={isEven ? 0 : "md"}
      >
        {isImageLoading ? (
          <Center>
            <CircularProgress isIndeterminate={true} color="primary.400" />
          </Center>
        ) : (
          <Box w="full">{page}</Box>
        )}
      </Box>
    </PhotoBookPageWrapper>
  )

  const desktopComponent = (
    <Flex
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      transform={CSS.Translate.toString(transform)}
      transition={transition || undefined}
      cursor="grab"
      justify={isDragging ? "center" : isOdd ? "flex-start" : "flex-end"}
    >
      <Box
        p={1}
        boxShadow="0px 2.1px 12.57px 0px rgba(0, 0, 0, 0.20);"
        borderLeftRadius={isOdd ? 0 : "md"}
        borderRightRadius={isEven ? 0 : "md"}
      >
        {isImageLoading ? (
          <Center w={boxWidth} h="full">
            <CircularProgress isIndeterminate={true} color="primary.400" />
          </Center>
        ) : (
          <Box w={boxWidth}>{page}</Box>
        )}
      </Box>
    </Flex>
  )

  return isMobile ? mobileComponent : desktopComponent
}

export default PhotoBookPage

export const DeleteSpreadConfirmation: React.FC<{
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}> = ({ onClose, onConfirm, isOpen }) => {
  const { t } = useTranslation()

  return (
    <ConfirmationModal
      title={t(
        "components.editors.common.pagination.PhotoBookPageNavigationButton.DeleteSpreadConfirmation.Title"
      )}
      description={t(
        "components.editors.common.pagination.PhotoBookPageNavigationButton.DeleteSpreadConfirmation.Message"
      )}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmLabel={t(
        "components.editors.common.pagination.PhotoBookPageNavigationButton.DeleteSpreadConfirmation.ConfirmLabel"
      )}
      cancelLabel={t(
        "components.editors.common.pagination.PhotoBookPageNavigationButton.DeleteSpreadConfirmation.CancelLabel"
      )}
      isCancelVisible={true}
      isOpen={isOpen}
      isCentered={true}
    />
  )
}
