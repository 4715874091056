import { Box, Heading, useDisclosure, VStack } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil"
import LineItemProductSelector from "~/components/cart/LineItemProductSelector"
import ConfirmationModal from "~/components/ConfirmationModal"
import { useHasImagesForAllImageRegions } from "~/hooks/useHasImagesForAllImageRegions"
import { useImage } from "~/hooks/useImage"
import { useImageRegion } from "~/hooks/useImageRegion"
import { useLineItem, useLineItemActions } from "~/hooks/useLineItem"
import { useProcessActions } from "~/hooks/useProcessActions"
import { useProductPage } from "~/hooks/useProductPage"
import { useUpload } from "~/hooks/useUpload"
import { selectedRegion } from "../common/atoms"
import EditorActionButtons from "../common/EditorActionButtons"
import EditorAddPagesTool from "../common/EditorAddPagesTool"
import EditorChangeImageButton from "../common/EditorChangeImageButton"
import EditorRotateTool from "../common/EditorRotateTool"
import EditorSidebarContainer from "../common/EditorSidebarContainer"
import EditorZoomSlider from "../common/EditorZoomSlider"
import { currentSelectedPageIdFamily } from "../common/pagination/atoms"

interface Props {
  lineItemId: EntityId
  showTools: boolean
  onContinue: () => void
  onCancel: () => void
}

const PhotoBookEditorSideBar: React.FC<Props> = ({
  lineItemId,
  showTools,
  onContinue,
  onCancel,
}) => {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { lineItem } = useLineItem(lineItemId)
  const [currentPageId, setCurrentPageId] = useRecoilState(
    currentSelectedPageIdFamily(lineItemId)
  )
  const { type: selectedRegionType, id: selectedRegionId } =
    useRecoilValue(selectedRegion)
  const { applyEditorChanges } = useProcessActions()
  const { productPage } = useProductPage(currentPageId)

  const imageRegionId =
    selectedRegionType === "image"
      ? selectedRegionId
      : productPage?.imageRegionIds[0]

  const { imageRegion } = useImageRegion(imageRegionId ?? 0)
  const { upload } = useUpload(imageRegion?.uploadId!)
  const { image } = useImage(upload?.imageId!)
  const hasImagesForAllImageRegions = useHasImagesForAllImageRegions(lineItemId)
  const hasMultiplePages = lineItem.productPageIds.length > 1

  const imageTransformId = image?.imageTransformId!
  const { updateProduct, remove } = useLineItemActions()

  const handleProductChange = useCallback(
    (productId: EntityId) => {
      // navigate to the first page before changing product
      setCurrentPageId(lineItem.productPageIds[0])
      updateProduct(lineItemId, productId)
    },
    [lineItem.productPageIds, lineItemId, setCurrentPageId, updateProduct]
  )

  const handleSaveChanges = useRecoilCallback(({ snapshot }) => async () => {
    applyEditorChanges({ lineItemId, snapshot })
    onContinue()
    onClose()
  })

  const onContinueEnhanced = useCallback(() => {
    if (!hasImagesForAllImageRegions) {
      onOpen()
      return
    }
    handleSaveChanges()
  }, [handleSaveChanges, hasImagesForAllImageRegions, onOpen])

  const handleCancel = useCallback(() => {
    if (!lineItem.isConfirmed) {
      remove(lineItem.id)
    }
    onCancel()
  }, [lineItem, onCancel, remove])

  const content = (
    <>
      <Box borderBottomWidth={1} mb={6}>
        <Heading as="h2" size="lg" mb={3}>
          {t("components.editors.print.PrintEditorSidebar.Options")}
        </Heading>
      </Box>
      <Box>
        <VStack align="left" spacing={6} overflow="auto">
          <Box>
            <Heading as="h3" size="sm" mb={3}>
              {t("components.editors.print.PrintEditorSidebar.Customize")}
            </Heading>
            <VStack spacing={2} width="full">
              <LineItemProductSelector
                currentProductId={lineItem!.productId}
                onProductChange={handleProductChange}
              />
              {showTools && (
                <EditorChangeImageButton
                  imageRegionId={imageRegion?.id!}
                  lineItemId={lineItemId}
                />
              )}
            </VStack>
          </Box>

          <Box>
            <Heading as="h3" size="sm" mb={3}>
              {t("components.editors.print.PrintEditorSidebar.RotateImage")}
            </Heading>
            <EditorRotateTool
              imageRegionId={imageRegion?.id!}
              imageTransformId={imageTransformId}
            />
          </Box>
          <Box>
            <Heading as="h3" size="sm" mb={3}>
              {t("components.editors.print.PrintEditorSidebar.ScaleImage")}
            </Heading>
            <EditorZoomSlider
              imageTransformId={imageTransformId}
              disabled={false}
            />
          </Box>

          <Box>
            <Heading as="h3" size="sm" mb={3}>
              {t(
                "components.editors.print.PrintEditorSidebar.PhotoBookOptions"
              )}
            </Heading>
            <EditorAddPagesTool
              lineItemId={lineItemId}
              selectedProductPageId={currentPageId}
            />
          </Box>
        </VStack>
      </Box>

      <EditorNoPhotoConfirmation
        onClose={onClose}
        onConfirm={onClose}
        isOpen={isOpen}
        hasMultiplePages={hasMultiplePages}
      />
    </>
  )

  const actions = (
    <EditorActionButtons
      onContinue={onContinueEnhanced}
      onCancel={handleCancel}
      viewType="desktop"
    />
  )

  return <EditorSidebarContainer content={content} actions={actions} />
}

export default PhotoBookEditorSideBar

export const EditorNoPhotoConfirmation: React.FC<{
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  hasMultiplePages: boolean
}> = ({ onClose, onConfirm, isOpen, hasMultiplePages }) => {
  const { t } = useTranslation()

  const message = hasMultiplePages
    ? t(
        "components.editors.print.PrintEditorSidebar.EditorNoPhotoConfirmation.DescriptionMultiplePages"
      )
    : t(
        "components.editors.print.PrintEditorSidebar.EditorNoPhotoConfirmation.Description"
      )

  return (
    <ConfirmationModal
      title={t(
        "components.editors.print.PrintEditorSidebar.EditorNoPhotoConfirmation.Title"
      )}
      description={message}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmLabel={t(
        "components.editors.print.PrintEditorSidebar.EditorNoPhotoConfirmation.ConfirmLabel"
      )}
      isCancelVisible={false}
      isOpen={isOpen}
      isCentered={true}
    />
  )
}
