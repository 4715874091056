import { Box, Flex } from "@chakra-ui/react"
import { EntityId } from "@jackfruit/common"
import React from "react"
import { useRecoilValue } from "recoil"
import { useLineItem } from "~/hooks/useLineItem"
import { usePhotoBook } from "~/hooks/usePhotoBook"
import PageEditor from "../common/PageEditor"
import { currentSelectedPageIdFamily } from "../common/pagination/atoms"
import { ViewType } from "../Editor"
import PageEditorPager from "./pagination/PageEditorPager"
import PageEditorPagerMobile from "./pagination/PageEditorPagerMobile"
import PageEditorPagerMobileLandscape from "./pagination/PageEditorPagerMobileLandscape"
import ProductPageRendererWrapper from "./ProductPageRendererWrapper"

const isDebug = false

interface Props {
  lineItemId: EntityId
  viewType?: ViewType
}

const MultiplePagesEditor: React.FC<Props> = ({
  lineItemId,
  viewType = "desktop",
}) => {
  const { lineItem } = useLineItem(lineItemId)
  const { productPageIds, productTemplateId, productTemplateVariantId } =
    lineItem
  const currentSelectedPageId = useRecoilValue(
    currentSelectedPageIdFamily(lineItemId)
  )

  const isPhotoBook = usePhotoBook(lineItemId)
  const hasMultiplePages = productPageIds.length > 1
  const isMobile = viewType === "mobile"
  const isMobileLandscape = viewType === "mobile landscape"

  const padding = viewType === "mobile" ? "0px" : "25px"

  const isMobileWithMultiplePages = isMobile && hasMultiplePages
  const isMobileLandscapeWithMultiplePage =
    isMobileLandscape && hasMultiplePages
  const isEverythingElse =
    !isMobileWithMultiplePages && !isMobileLandscapeWithMultiplePage

  const pageEditor = (
    <Box h="full">
      {isDebug && (
        <Box width="150px" position="absolute">
          <ProductPageRendererWrapper
            lineItemId={lineItemId}
            productPageId={currentSelectedPageId}
          />
        </Box>
      )}
      <PageEditor
        viewType={viewType}
        lineItemId={lineItemId}
        productPageId={currentSelectedPageId}
      />
    </Box>
  )

  return (
    <Flex
      p={padding}
      flex={1}
      flexDirection="column"
      data-product-template-id={productTemplateId}
      data-product-template-variant-id={productTemplateVariantId}
    >
      {isMobileWithMultiplePages && (
        <PageEditorPagerMobile lineItemId={lineItemId}>
          {pageEditor}
        </PageEditorPagerMobile>
      )}
      {isMobileLandscapeWithMultiplePage && (
        <PageEditorPagerMobileLandscape lineItemId={lineItemId}>
          {pageEditor}
        </PageEditorPagerMobileLandscape>
      )}
      {isEverythingElse && (
        <>
          {pageEditor}
          {hasMultiplePages && !isPhotoBook && (
            <PageEditorPager lineItemId={lineItemId} />
          )}
        </>
      )}
    </Flex>
  )
}

export default MultiplePagesEditor
