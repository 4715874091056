import { EntityId } from "@jackfruit/common"
import { useApplicationState } from "./useApplicationState"
import { useLineItem } from "./useLineItem"
import { useProductImages } from "./useProductImages"

/**
 * @param id - The line item ID corresponding to the photo book.
 */

const defaultLocale = "en-US"
export const usePhotoBook = (id: EntityId) => {
  const { language } = useApplicationState()
  const { lineItem, product } = useLineItem(id)
  const { productImages } = useProductImages(lineItem.productId)

  const isPhotoBook = product.categoryName === "photo-book"

  const photoBookFrame = productImages.filter(
    image => image.metaData?.editorFrame !== undefined
  )[0]

  const photoBookCoverDefaultThumbnail = productImages.filter(
    image => image.metaData?.photoBook?.staticCoverThumbnail
  )[0]

  const photoBookCoverThumbnail = productImages.filter(
    image =>
      image.metaData?.photoBook?.staticCoverThumbnail &&
      (image.metaData?.photoBook?.locale ?? defaultLocale) === language
  )[0]

  const photoBookCoverDefaultPreview = productImages.filter(
    image => image.metaData?.photoBook?.staticCoverPreview
  )[0]

  const photoBookCoverPreview = productImages.filter(
    image =>
      image.metaData?.photoBook?.staticCoverPreview &&
      (image.metaData?.photoBook?.locale ?? defaultLocale) === language
  )[0]

  const hasCoverDefault =
    photoBookCoverDefaultThumbnail && photoBookCoverDefaultPreview

  const hasCoverPreview = photoBookCoverThumbnail && photoBookCoverPreview

  const hasCover = hasCoverPreview || hasCoverDefault

  return {
    isPhotoBook,
    hasCover,
    photoBookFrame,
    photoBookCoverThumbnail:
      photoBookCoverThumbnail || photoBookCoverDefaultThumbnail,
    photoBookCoverPreview:
      photoBookCoverPreview || photoBookCoverDefaultPreview,
  }
}
